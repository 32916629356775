.lobby-container {
    width: 540px;
    height: 960px;

    .lobby-header-container {
        width: 540px;
        height: 122px; //72 + 50
    }

    .lobby-main-container {
        margin-left: 0px;
        padding-bottom: 15px;
        overflow-y: scroll;
        max-height: 838px;
    }

    .centered-text {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh; /* Adjust as needed */
    }
}

.new-ui-button {
    border-radius: 1rem;
    display: inline-flex;
    padding: 0 1rem 0 0;
    align-items: center;
    gap: 0rem;

    text-transform: uppercase;
    background: linear-gradient(
        272.43deg,
        #433627 0%,
        #7f694c 50%,
        #433627 100%
    );
    img {
        scale: 0.5;
    }
    .text-label {
        background: linear-gradient(180deg, #ebfff6 50%, #b4bbb8 50%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
        // text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
        filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.5));
        font-size: 1.125rem;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.03375rem;
        text-transform: uppercase;
        text-wrap: nowrap;
    }
}
.roulette-two-column-main-container {
    .new-game-cell-label-container {
        .new-game-cell-label {
            width: 90%;
            top: 0px;
            .new-game-cell-label-text {
                font-size: 14px;
                top: 1px;
            }
        }
    }
    .new-game-cell-label {
        top: 24%;
        bottom: 23%;
    }
}
.roadmap-box {
    .new-game-cell-label-container {
        top: 0px;
        height: 106%;
        .new-game-cell-label {
            top: 0px;
            width: 96%;
            .new-game-cell-label-text {
                top: 1%;
                font-size: 13px;
            }
        }
    }
    .new-game-cell-label {
        top: 24%;
        bottom: 23%;
    }
}
.new-game-cell-label-container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    // background-color: #0000003c;
    display: flex;
    justify-content: center;
    .new-game-cell-label {
        position: relative;
        width: 55%;
        // top: 35%;
        // bottom: 35%;
        // background: linear-gradient(86.48deg, #ff0701 74.43%, #960703 100%);
        .new-game-cell-label-text {
            position: absolute;
            top: 0px;
            right: 0px;
            text-wrap: nowrap;
            font-size: 22px;
            color: #fff;
            width: 100%;
            height: 100%;
            text-align: center;
            align-content: center;
        }
    }
}
.newgame-switch-table-map-main-container {
    height: 100px;
    width: 100%;
    background-color: #fff;
    overflow: hidden;
}
