.baccarat-bet-area {
    margin-top: 330px; //50+300-22//52=header,300=video,22=betPage
    width: 540px;
    height: 334px;
    .tap-area {
        display: 'inline-block';
        height: 244px;
        position: 'relative';
        overflow: 'hidden';
    }
    .chip-container {
        margin-top: '2px';
        padding-left: '5px';
        padding-right: '5px';
    }
}

.bacc-new-sidebet-fade-bg {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    animation: bacc-new-sidebet-fade-bg 2s 3;
}
@keyframes bacc-new-sidebet-fade-bg {
    0% {
        background: #997829;
    }

    33% {
        background: #cea645;
    }

    100% {
        background: #997829;
    }
}
