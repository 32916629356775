.new-ui-popup {
    position: absolute;
    background-color: #000000b3;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-content: center;
    .new-ui-container {
        position: relative;
        display: grid;
        width: 80%;
        height: 100%;
        align-content: center;
        img {
            width: 100%;
        }
        input {
            accent-color: rgba(60, 112, 103, 1);
        }
        .MuiButtonBase-root {
            border-radius: 0px;
        }
        .new-ui-try-button {
            // position: absolute;
            width: 100%;
            height: 100%;
            // bottom: 11rem;
            // left: 28.5%;
        }
    }
    .new-ui-header {
        display: grid;
        justify-content: end;
    }
    .checkbox-wrapper {
        color: #c59f67;
        display: flex;
        justify-content: center;
        position: relative;
        cursor: pointer;
        > label {
            position: absolute;
            bottom: 30px;
        }
    }
}
